import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'
import Container from 'components/Container'
import Paragraph from 'components/Paragraph'
import Title from 'components/Title'
import Link from 'components/Link'
import LanguageSwitcher from 'components/LanguageSwitcher'

import { linkResolver } from '../utils/linkResolver'

import logo from 'assets/images/logo-white.svg'
import innerHTML from '../utils/innerHTML'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismicFooterFr: prismicFooter(lang: { eq: "fr-fr" }) {
        data {
          footer_top_left {
            text
          }
          footer_top_right {
            html
          }
          col_1_title {
            text
          }
          col_2_title {
            text
          }
          col_3_title {
            text
          }
          group_1_subtitle
          links_1_1 {
            label
            open_in_new_tab
            link {
              type
              slug
              uid
              link_type
              url
            }
          }
          group_2_subtitle
          links_1_2 {
            label
            open_in_new_tab
            link {
              type
              slug
              uid
              link_type
              url
            }
          }
          links_2 {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          links_3 {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          address_col_1 {
            html
          }
          address_col_2 {
            html
          }
          bottom_links {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          bottom_text {
            html
          }
        }
      }
      prismicFooterEn: prismicFooter(lang: { eq: "en-gb" }) {
        data {
          footer_top_left {
            text
          }
          footer_top_right {
            html
          }
          col_1_title {
            text
          }
          col_2_title {
            text
          }
          col_3_title {
            text
          }
          group_1_subtitle
          links_1_1 {
            label
            open_in_new_tab
            link {
              type
              slug
              uid
              link_type
              url
            }
          }
          group_2_subtitle
          links_1_2 {
            label
            open_in_new_tab
            link {
              type
              slug
              uid
              link_type
              url
            }
          }
          links_2 {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          links_3 {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          address_col_1 {
            html
          }
          address_col_2 {
            html
          }
          bottom_links {
            label
            open_in_new_tab
            link {
              type
              uid
              link_type
              url
            }
          }
          bottom_text {
            html
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const document = intl.locale === 'en' ? data.prismicFooterEn.data : data.prismicFooterFr.data

  return (
    <footer className='Footer'>
      <div className='Footer-top'>
        <Container className='Footer-topContent' size='small'>
          <Title tag='h4' className='Footer-topTitle' color='white'>{ document.footer_top_left.text }</Title>
          <Paragraph style='small' color='white' className='Footer-topText'>
            <p>{ intl.formatMessage({ id: 'footer.contact_us' }) } <Link to='/contactez-nous'>{ intl.formatMessage({ id: 'footer.form' }) }</Link></p>
          </Paragraph>
        </Container>
      </div>
      <div className='Footer-bottom'>
        <Container className='Footer-bottomContent' size='small'>
          <div className='Footer-sitemap'>
            <div className='Footer-sitemapColumn'>
              <Title tag='h6' color='white'>{ document.col_1_title.text }</Title>
              <ul className='Footer-sitemapLinks'>
                <li className='Footer-sitemapLink'>
                  <span>{ document.group_1_subtitle }</span>
                </li>
                { document.links_1_1.map((item, i) => (
                  <li className='Footer-sitemapLink' key={ `col-1-group-1-item-${i}` }>
                    <Link to={ linkResolver(item.link) } openInNewTab={ item.open_in_new_tab }>{ item.label }</Link>
                  </li>
                ))}
              </ul>
              <ul className='Footer-sitemapLinks'>
                <li className='Footer-sitemapLink'>
                  <span>{ document.group_2_subtitle }</span>
                </li>
                { document.links_1_2.map((item, i) => (
                  <li className='Footer-sitemapLink' key={ `col-1-group-2-item-${i}` }>
                    <Link to={ linkResolver(item.link) } openInNewTab={ item.open_in_new_tab }>{ item.label }</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='Footer-sitemapColumn'>
              <Title tag='h6' color='white'>{ document.col_2_title.text }</Title>
              <ul className='Footer-sitemapLinks'>
                { document.links_2.map((item, i) => (
                  <li className='Footer-sitemapLink' key={ `col-2-item-${i}` }>
                    <Link to={ linkResolver(item.link) } openInNewTab={ item.open_in_new_tab }>{ item.label }</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='Footer-sitemapColumn'>
              <Title tag='h6' color='white'>{ document.col_3_title.text }</Title>
              <ul className='Footer-sitemapLinks'>
                { document.links_3.map((item, i) => (
                  <li className='Footer-sitemapLink' key={ `col-3-item-${i}` }>
                    <Link to={ linkResolver(item.link) } openInNewTab={ item.open_in_new_tab }>{ item.label }</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='Footer-address'>
            <div className='Footer-addressLeft'>
              <div className='Footer-addressLeftColumn' { ...innerHTML(document.address_col_1.html) } />
              <div className='Footer-addressLeftColumn' { ...innerHTML(document.address_col_2.html) } />
            </div>
            <Link to='/' className='Footer-addressRight'>
              <img src={ logo } />
            </Link>
          </div>
          <div className='Footer-legal'>
            <ul className='Footer-legalLeft'>
              { document.bottom_links.map((item, i) => (
                <li className='Footer-legalLeftItem' key={ `bottom-item-${i}` }>
                  <Link to={ linkResolver(item.link) } openInNewTab={ item.open_in_new_tab }>{ item.label }</Link>
                </li>
              ))}
              <LanguageSwitcher />
            </ul>
            <div className='Footer-legalRight' { ...innerHTML(document.bottom_text.html) } />
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
