import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation } from '@reach/router'

import isExternalLink from '../utils/isExternalLink'

const Link = ({
  to,
  className = '',
  openInNewTab = false,
  children,
  ...props
}) => {
  const { pathname } = useLocation()

  if (isExternalLink(to)) {
    let tagProps = {}

    if (openInNewTab) {
      tagProps = {
        target: '_blank',
        rel: 'noreferrer'
      }
    }

    return (
      <a
        className={ `Link ${className}` }
        href={ to }
        { ...tagProps }
        { ...props }
      >
        { children }
      </a>
    )
  } else {
    return (
      <GatsbyLink
        className={ `Link ${className}${pathname === to ? ' Link--isCurrentPage' : ''}` }
        to={ to } { ...props }
        target={ openInNewTab ? '_blank' : '_self' }
      >
        { children }
      </GatsbyLink>
    )
  }
}

export default Link
