import React from 'react'
import PropTypes from 'prop-types'

const Container = ({
  children,
  className = '',
  size = 'large',
  tag = 'div'
}) => {
  const HTMLTag = tag

  return (
    <HTMLTag className={ `Container Container--${size} ${className}` }>
      { children }
    </HTMLTag>
  )
}

Container.propTypes = {
  children: PropTypes.node
}

export default Container
