import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Button = ({
  to = '',
  children,
  onClick,
  className = '',
  style = 'orange',
  size = 'medium',
  openInNewTab = false
}) => {
  if (typeof onClick === 'function') {
    return (
      <span className={ `Button Button--${style} Button--${size} ${className}` } onClick={ onClick }>{ children }</span>
    )
  }

  if (to) {
    return (
      <Link openInNewTab={ openInNewTab } className={ `Button Button--${style} Button--${size} ${className}` } to={ to }>{ children }</Link>
    )
  }
}

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.string,
  size: PropTypes.string,
  openInNewTab: PropTypes.bool
}

export default Button
