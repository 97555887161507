import React from 'react'
import innerHTML from '../utils/innerHTML'

const Title = ({
  tag = 'h1',
  children,
  color = 'darkBlue',
  className = '',
  textAlign = '',
  ...props
}) => {
  const HTMLTag = tag

  return (
    <HTMLTag
      className={
      `Title 
      ${tag && `Title--${tag}`} 
      ${color && `Title--${color}`} 
      ${textAlign && `Paragraph--${textAlign}`} 
      ${className}`
    }
      { ...innerHTML(children) }
      { ...props }
    />
  )
}

export default Title
