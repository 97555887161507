
import React from 'react'
import { useIntl } from 'react-intl'
import { useAtom } from 'jotai'
import languages from '../i18n'
import { alternateLanguagesState } from '../atoms/languageSwitcher'
import Link from 'components/Link'
import { linkResolver } from '../utils/linkResolver'

const LanguageSwitcher = ({
  shortLabels = false,
  className = ''
}) => {
  const intl = useIntl()
  const [alternateLanguages] = useAtom(alternateLanguagesState)

  return (
    <div className={ `LanguageSwitcher ${className}` }>
      {languages.map((language, i) => {
        if (language.locale !== intl.locale) {
          const alternateLanguage = alternateLanguages.find((l) => l.lang === language.prismicLocale)

          return (
            <Link
              key={ `language-${i}` }
              to={ alternateLanguage
                ? alternateLanguage.skipResolver
                  ? alternateLanguage.url
                  : linkResolver(alternateLanguage)
                : language.home }
              className='LanguageSwitcher-item'
            >
              {shortLabels ? language.shortLabel : language.label}
            </Link>
          )
        }
      })}
    </div>
  )
}

export default LanguageSwitcher
