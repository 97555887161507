import React from 'react'
import { useIntl } from 'react-intl'
import Link from 'components/Link'
import Navigation from 'components/Navigation'
import MobileNavigation from 'components/MobileNavigation'
import languages from '../i18n'
import logo from 'assets/images/logo.svg'

const Header = (props) => {
  const intl = useIntl()
  const homeUrl = languages.find((language) => language.locale === intl.locale).home

  return (
    <header className='Header'>
      <div className='Header-left'>
        <Link to={ homeUrl } className='Header-logo'>
          <img src={ logo } />
        </Link>
      </div>
      <Navigation />
      <MobileNavigation />
    </header>
  )
}

export default Header
