
import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import gsap from 'gsap'
import { useLocation, globalHistory } from '@reach/router'
import { useIntl } from 'react-intl'
import Title from 'components/Title'
import Link from 'components/Link'
import Button from 'components/Button'
import LanguageSwitcher from 'components/LanguageSwitcher'
import useScrollBlock from '../utils/useScrollBlock'
import logoWhite from 'assets/images/logo-white.svg'

const MobileNavigation = () => {
  const data = useStaticQuery(graphql`
    query MobileNavigationQuery {
      prismicMobileMenuFr: prismicMobileMenu(lang: { eq: "fr-fr" }) {
        data {
          offers_menu_label
          offers {
            label
            link
          }
          group_1_title
          group_1_items {
            label
            link
          }
          group_2_title
          group_2_items {
            label
            link
          }
          buttons {
            label
            style
            link
            new_tab
          }
        }
      }
      prismicMobileMenuEn: prismicMobileMenu(lang: { eq: "en-gb" }) {
        data {
          offers_menu_label
          offers {
            label
            link
          }
          group_1_title
          group_1_items {
            label
            link
          }
          group_2_title
          group_2_items {
            label
            link
          }
          buttons {
            label
            style
            link
            new_tab
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const document = intl.locale === 'en' ? data.prismicMobileMenuEn.data : data.prismicMobileMenuFr.data
  const [blockScroll, allowScroll] = useScrollBlock()
  const location = useLocation()
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
  const [isOffersMenuUnfolded, setIsOffersMenuUnfolded] = useState(false)

  const navRef = useRef()

  globalHistory.listen(({ action }) => {
    if (action === 'PUSH') {
      allowScroll()
    }
  })

  useEffect(() => {
    if (isMobileNavigationOpen) {
      gsap.to(navRef.current, {
        duration: 0.3,
        autoAlpha: 1,
        onStart: () => {
          blockScroll()
        }
      })
    } else {
      gsap.to(navRef.current, {
        duration: 0.3,
        autoAlpha: 0,
        onComplete: () => {
          allowScroll()
          setIsOffersMenuUnfolded(false)
        }
      })
    }
  }, [isMobileNavigationOpen])

  useEffect(() => {
    gsap.to('.MobileNavigation-group--foldable .MobileNavigation-links', {
      duration: 0.3,
      height: isOffersMenuUnfolded ? 'auto' : 0
    })
  }, [isOffersMenuUnfolded])

  useEffect(() => {
    setIsMobileNavigationOpen(false)
  }, [location])

  return (
    <nav className={ `MobileNavigation ${isMobileNavigationOpen ? 'MobileNavigation--open' : ''}` }>
      <div className='MobileNavigation-toggle' onClick={ () => setIsMobileNavigationOpen(!isMobileNavigationOpen) }>
        <div className={ `MobileNavigation-burger ${isMobileNavigationOpen ? 'MobileNavigation-burger--open' : ''}` }>
          <span />
          <span />
        </div>
      </div>
      <div className='MobileNavigation-pane' ref={ navRef }>
        <Link to='/' className='MobileNavigation-logo'>
          <img src={ logoWhite } />
        </Link>
        <div className='MobileNavigation-content'>
          <div className={ `MobileNavigation-group MobileNavigation-group--foldable ${isOffersMenuUnfolded ? 'MobileNavigation-group--unfolded' : ''}` }>
            <Title tag='h2' color='white' className='MobileNavigation-groupTitle' onClick={ () => setIsOffersMenuUnfolded(!isOffersMenuUnfolded) }>{ document.offers_menu_label }</Title>
            <ul className='MobileNavigation-links'>
              {document.offers.map((item, i) => (
                <li className='MobileNavigation-link' key={ `offers-1-${i}` }>
                  <Link to={ item.link } className='Navigation-submenuItemLink'>{item.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='MobileNavigation-group'>
            <Title tag='h2' color='white' className='MobileNavigation-groupTitle'>{document.group_1_title}</Title>
            <ul className='MobileNavigation-links'>
              {document.group_1_items.map((item, i) => (
                <li className='MobileNavigation-link' key={ `group-1-${i}` }>
                  <Link to={ item.link }>{item.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='MobileNavigation-group'>
            <Title tag='h2' color='white' className='MobileNavigation-groupTitle'>{document.group_2_title}</Title>
            <ul className='MobileNavigation-links'>
              {document.group_2_items.map((item, i) => (
                <li className='MobileNavigation-link' key={ `group-2-${i}` }>
                  <Link to={ item.link }>{item.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          {document.buttons.map((item, i) => (
            <Button key={ `button-${i}` } style={ item.style } to={ item.link } openInNewTab={ item.new_tab }>{item.label}</Button>
          ))}
          <LanguageSwitcher className='MobileNavigation-languageSwitcher' />
        </div>
      </div>
    </nav>
  )
}

export default MobileNavigation
