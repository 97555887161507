
import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'
import gsap from 'gsap'
import { useDetectClickOutside } from 'react-detect-click-outside'
import Button from 'components/Button'
import Link from 'components/Link'
import LanguageSwitcher from 'components/LanguageSwitcher'

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      prismicHeaderFr: prismicHeader(lang: { eq: "fr-fr" }) {
        data {
          offers_menu_label
          offers_1 {
            label
            link
          }
          offers_2 {
            label
            link
          }
          items {
            label
            link
          }
          buttons {
            label
            style
            link
            new_tab
          }
        }
      }
      prismicHeaderEn: prismicHeader(lang: { eq: "en-gb" }) {
        data {
          offers_menu_label
          offers_1 {
            label
            link
          }
          offers_2 {
            label
            link
          }
          items {
            label
            link
          }
          buttons {
            label
            style
            link
            new_tab
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const document = intl.locale === 'en' ? data.prismicHeaderEn.data : data.prismicHeaderFr.data
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setIsSubmenuOpen(false) })
  const submenuRef = useRef()

  useEffect(() => {
    gsap.to(submenuRef.current, {
      duration: 0.3,
      autoAlpha: isSubmenuOpen ? 1 : 0
    })
  }, [isSubmenuOpen])

  return (
    <nav className='Navigation' ref={ ref }>
      <ul className='Navigation-nav'>
        <li className='Navigation-navItem'>
          <span className='Navigation-navItemLink' onMouseEnter={ () => setIsSubmenuOpen(!isSubmenuOpen) }>
            <span>{ document.offers_menu_label }</span>
            <i className={ isSubmenuOpen ? 'icon-chevron-up' : 'icon-chevron-down' } />
          </span>
          <div ref={ submenuRef } className='Navigation-submenu' onMouseLeave={ () => setIsSubmenuOpen(false) }>
            <ul className='Navigation-submenuCol'>
              <li className='Navigation-submenuItem'>{ intl.formatMessage({ id: 'nav.individuals' }) }</li>
              {document.offers_1.map((item, i) => (
                <li className='Navigation-submenuItem' key={ `offers-1-${i}` }>
                  <Link to={ item.link } className='Navigation-submenuItemLink'>{item.label}</Link>
                </li>
              ))}
            </ul>
            <ul className='Navigation-submenuCol'>
              <li className='Navigation-submenuItem'>{ intl.formatMessage({ id: 'nav.professionals' }) }</li>
              {document.offers_2.map((item, i) => (
                <li className='Navigation-submenuItem' key={ `offers-2-${i}` }>
                  <Link to={ item.link } className='Navigation-submenuItemLink'>{item.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </li>
        {document.items.map((item, i) => (
          <li className='Navigation-navItem' key={ `item-${i}` }>
            <Link to={ item.link } className='Navigation-navItemLink'>{item.label}</Link>
          </li>
        ))}
        <LanguageSwitcher className='Navigation-navItem' />
      </ul>
      <ul className='Navigation-buttons'>
        {document.buttons.map((item, i) => (
          <Button key={ `button-${i}` } style={ item.style } size='small' to={ item.link } openInNewTab={ item.new_tab }>{item.label}</Button>
        ))}
      </ul>
    </nav>
  )
}

export default Navigation
