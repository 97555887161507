import React from 'react'

const P = ({
  children,
  className = '',
  style = 'normal',
  color = '',
  textAlign = '',
  ...props
}) => {
  return (
    <p
      className={
        `Paragraph 
        Paragraph--${style} 
        ${color && `Paragraph--${color}`} 
        ${textAlign && `Paragraph--${textAlign}`} 
        ${className}`
      }
      { ...props }
    >
      {children}
    </p>
  )
}

export default P
