const languages = require('../i18n')

const linkResolver = (doc) => {
  const currentLanguage = doc.lang || languages.find((language) => language.default).prismicLocale
  const languageConfig = languages.find((language) => language.prismicLocale === currentLanguage)

  if (doc.link_type === 'Web') {
    return doc.url
  }

  if (doc.type === 'offer') {
    return `${languageConfig.prefix}/${languageConfig.slugs.offers}/${doc.uid}`
  }

  if (doc.type === 'blog_post') {
    return `${languageConfig.prefix}/${languageConfig.slugs.blog}/${doc.uid}`
  }

  if (doc.type === 'simple_page') {
    return `${languageConfig.prefix}/${doc.uid}`
  }

  if (doc.type === 'about_page') {
    return `${languageConfig.prefix}/${languageConfig.slugs.aboutUs}`
  }

  if (doc.type === 'homepage') {
    return languageConfig.home
  }

  return languageConfig.prefix
}

exports.linkResolver = linkResolver
